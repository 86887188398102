* {
  box-sizing: border-box;
}
.body1{
    overflow-x:hidden;
    top: 0;
    width: 100%;
  background-size: cover; /* Ensures the image covers the entire screen */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100vh;
}

.centered-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure text is above the image */
}

.title-main1 {
  font-size: 200px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin: 0; /* Remove default margin */
}

.title-main2 {
  font-size: 100px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin: 0; /* Remove default margin */
   /* Ensure it scales relative to viewport width */
}
.title-main1, .title-main2 {
  color: white; /* Ensure text is visible against the background */
}
@media (max-width: 768px) {
  .title-main1 {
      font-size: 80px; /* Smaller font size for mobile */
  }
  

  .title-main2 {
      font-size: 40px; /* Smaller font size for mobile */
       /* Adjust width for smaller screens */
  }
}

.about-us {
  width: 100%;
  background-image: url('../images/second/about-us2.jpg');
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text within the container */
  color: aqua; /* Text color */
  padding: 0 20px; /* Optional padding for better spacing on small screens */
  box-sizing: border-box; /* Ensure padding is included in width/height calculations */
}

.about-us-content {
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: flex-start; /* Align items to the start of the container */
  width: 100%;
  max-width: 1200px; /* Optional max-width to prevent stretching on large screens */
}

.about-us-text1 {
  font-size: 70px;
  font-weight: 600;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin-left: 5%;
  color: white;
   /* Space between the title and the description */
  
}

.about-us-text2 {
  font-size: 15px;
  font-weight: 300;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  line-height: 1.6; /* Line height for readability */
  width: 60%; /* Adjust width as needed */
  max-width: 800px;
  margin-left: 17%;
  color: white; /* Maximum width to ensure readability */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-us-text1 {
    font-size: 50px; /* Adjust font size for tablets */
  }

  .about-us-text2 {
    font-size: 14px; /* Adjust font size for tablets */
  }
  
}

@media (max-width: 480px) {
  .about-us-text1 {
    font-size: 50px; /* Adjust font size for mobile devices */
    margin-right: -75vw;
    margin-top: -120vh; /* Reduce space between the title and the description */
  }

  .about-us-text2 {
    font-size: 12px;
      
    width: 90%;
    height: 60vh;
    margin-left: 0vw;
    margin-top: -40vh; /* Full width on mobile devices */
  }
  .about-us{
    height: 150vh;
  }
}

.contact-us{
    width: 100%;
    background-image: url('../images/second/contact.jpeg');
    
    
  background-size: cover; /* Ensures the image covers the entire screen */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100vh;

}
.contact-title{
    color: 	#008000;
    font-size: 70px;
    font-weight: 600;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    position: relative;
    left: 70%;
    top: 20%;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
}
.contact-input{
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 40vh;
    width: 30vw;
    color: 	#008000;
    font-size: 22px;
    font-weight: 600;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    position: relative;
    left: 65%;
    top: 25%;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
.contact-input .form-group {
    display: flex;
    align-items: center; /* Aligns label and input vertically centered */
    margin-bottom: 20px;
  }
  
  .contact-input label {
    width: 120px; /* A consistent width for all labels */
    margin-right: 10px; /* Space between label and input */
  }
  
  .contact-input input,
  .contact-input textarea {
    flex: 1; /* Allows the input to take up remaining space */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .button{
    width: 80px;
    height: 40px;
    border-radius: 10px;
    margin-left: 50%;
    font-size: 20px;
    background-color:#008000;
    border: #008000;
    color: white;
  }
  .slideshow-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-in-out;
  }
  .image-slideshow {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .our-services {
    width: 100%;
    background-image: url('../images/second/our-serv2.jpg');
    background-size: cover; /* Ensures the image covers the entire screen */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    height: 100vh;
    padding: 20px; /* Padding inside the section */
  }
  
  .our-services-text1 {
    color: white;
    font-size: 70px;
    font-weight: 600;
    margin-bottom: 2%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center; /* Center align the title */
  }
  
  .service-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 items per row on desktop */
    gap: 20px; /* Space between the items */
    padding: 20px;
    
  }
  
  .service-item {
    background-color: grey; /* Grey background for each item */
    padding: 20px; /* Padding inside each item */
    border-radius: 10px; /* Rounded corners */
    text-align: left; /* Align text to the left */
    display: flex; /* Flexbox to align image and text */
    align-items: center; /* Center align items vertically */
    box-shadow: 0 4px 8px black; /* Optional shadow for a more elevated look */
    margin-right: 10px;
    max-height: fit-content;
    height: 33vh;
  }
  
  .service-item img {
    width:auto;
    height: 200px; /* Fixed height for images */
    border-radius: 10%; /* Rounded corners for images */
    margin-right: 30px; /* Space between image and text */
  }
  
  .service-item p {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    color: white;
  }
  
  .service-item ul {
    padding-left: 25px; /* Indent list */
    margin-top: 10px;
    font-size: 12px;
    list-style-type: disc;
    color: white;
  }
  
  .service-item ul li {
    margin-bottom: 5px;
  }
  
  /* Mobile view adjustments */
  @media (max-width: 768px) {
    .service-items {
      grid-template-columns: repeat(2, 1fr); /* 2 items per row for tablets */
    }
    .service-item img {
      width:auto;
      height: 100px; /* Fixed height for images */
      border-radius: 10%; /* Rounded corners for images */
      margin-right: 30px; /* Space between image and text */
    }
  }
  
  @media (max-width: 480px) {
    .our-services{
      height: max-content;
    }
    .service-items {
      grid-template-columns: 1fr;
       /* 1 item per row for small screens */
    }
    .service-item{
      height: 150px;
      width: 100%;
      left: 0;
      margin-left: -20px;
    }
    .service-item p {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
      color: white;
    }
    
    .service-item ul {
      padding-left: 10px; /* Indent list */
      margin-top: 10px;
      font-size: 9px;
      list-style-type: disc;
      color: white;
    }
    .our-services-text1 {
      color: white;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 2%;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      text-align: left;
      margin-left: 20px; /* Center align the title */
    }
    .contact-title{
      left: 10%;
      color:white;
    }
    .form-group{
      margin-left: -200%;
      color: white;
    }
    .button{
      margin-left: -80%;
      background-color: black;
    }
  }
  
  