.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: black;
    opacity: 0.6;
    position: absolute;
    z-index: 100;
    color: white;
    width: 100%;
    
  }
  
  .nav-left .nav-title {
    font-size: 24px;
    color: white;
    text-decoration: none;
  }
  
  .nav-right {
    display: flex;
  }
  
  .nav-btn {
    margin: 0 10px;
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .nav-btn:hover {
    color: #ccc;
  }
  
  /* Burger button for mobile */
  .burger-btn {
    display: none;
    font-size: 30px;
    cursor: pointer;
  }
  
  /* Mobile menu styling */
  .mobile-menu {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    padding: 20px;
    display: flex;
    flex-direction: column;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .mobile-menu .nav-btn {
    margin-bottom: 10px;
  }
  
  /* Show burger button for mobile screens */
  @media (max-width: 500px) {
    .burger-btn {
      display: block;
    }
    .navbar{
      width: 100%;
    }
  
    .nav-right {
      display: none;
    }
  }
  